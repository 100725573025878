import { CdkMenu, CdkMenuItem } from '@angular/cdk/menu';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { UserActions, userFeature } from '@expocab/entities/user';
import { ROUTE_TOKENS } from '@expocab/shared/app-config';
import { SvgPathPipe } from '@expocab/shared/libs';
import { Store } from '@ngrx/store';
import { SvgIconComponent } from 'angular-svg-icon';
import { shareReplay } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    CdkMenu,
    CdkMenuItem,
    RouterLink,
    SvgPathPipe,
    SvgIconComponent,
  ],
  selector: 'expocab-user-menu',
  standalone: true,
  styleUrl: './user-menu.component.scss',
  templateUrl: './user-menu.component.html',
})
export class UserMenuComponent {
  readonly ROUTE_TOKENS = ROUTE_TOKENS;

  readonly user$ = this.store
    .select(userFeature.selectUser)
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  constructor(private store: Store) {}

  logout(): void {
    this.store.dispatch(UserActions.userLogout());
  }
}
