<div
  class="notification notification_{{ notification.level }}"
  (mouseenter)="notificationHoverEvent(notification.id)"
>
  <expocab-notification-status
    class="notification__status"
    [icon]="notification.source === 'global' ? 'info' : 'flag'"
    [class.notification__status_new]="!notification.watched"
    [level]="notification.level"
  />
  <div>
    <p class="notification__title expocab-button-small">
      {{ notification.title }}
    </p>
    <span class="notification__date expocab-text-base">
      {{ notification.updated_at | date: 'dd/MM/yyyy, H:mm' }}
    </span>
    @if (notification.button_text) {
      @if (notification.button_action === buttonAction.Open) {
        <a
          class="notification__link expocab-text-base"
          [routerLink]="[ROUTE_TOKENS.NOTIFICATION + '/' + notification.id]"
          (click)="closeMenu()"
          >Читать далее</a
        >
      } @else if (notification.button_action === buttonAction.Download) {
        <a download target="_blank" [href]="notification.button_link">
          <expocab-button
            class="notification__button"
            size="small"
            icon="download"
            [text]="notification.button_text"
            [type]="notification.level === 'normal' ? 'secondary' : 'primary'"
          />
        </a>
      } @else if (notification.button_action === buttonAction.InternalLink) {
        <expocab-button
          class="notification__button"
          size="small"
          [text]="notification.button_text"
          [type]="notification.level === 'normal' ? 'secondary' : 'primary'"
          [routerLink]="[notification.button_link]"
          (click)="closeMenu()"
        />
      }
    }
  </div>
</div>
