import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';
import { ToastType } from '@expocab/shared/app-config';
import { SvgPathPipe } from '@expocab/shared/libs';
import { SvgIconComponent } from 'angular-svg-icon';
import { Toast } from 'ngx-toastr';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SvgPathPipe, SvgIconComponent],
  selector: 'expocab-toast',
  standalone: true,
  styleUrl: './toast.component.scss',
  templateUrl: './toast.component.html',
})
export class ToastComponent extends Toast implements OnInit {
  toastType!: string;

  private getIconName(value: ToastType): string {
    switch (value) {
      case 'expocab-error-toast':
        return 'alert';
      case 'expocab-info-toast':
        return 'info';
      case 'expocab-success-toast':
        return 'success';
      default:
        return 'info';
    }
  }

  ngOnInit(): void {
    this.toastType = this.getIconName(<ToastType>this.options.toastClass);
  }

  @HostBinding('style.--top') get top(): string {
    return this.options.positionClass;
  }
}
