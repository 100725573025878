<div class="app-container">
  <expocab-header />
  <main class="app-content" expocabScreenWidth>
    <router-outlet />
  </main>
  <expocab-footer class="app-footer" />
  <!-- <expocab-circle-button
    class="app-action-button"
    size="large"
    type="primary"
    icon="question"
    [cdkMenuTriggerFor]="actionMenu"
  /> -->
</div>

<ng-template #actionMenu>
  <expocab-action-menu />
</ng-template>
