import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { projectFeature } from '@expocab/entities/project';
import { ROUTE_TOKENS } from '@expocab/shared/app-config';
import { SvgPathPipe } from '@expocab/shared/libs';
import { getRouterSelectors } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { SvgIconComponent } from 'angular-svg-icon';
import { filter, map, shareReplay } from 'rxjs/operators';

import { LayoutGridComponent } from '../../grids';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    RouterLink,
    SvgPathPipe,
    SvgIconComponent,
    LayoutGridComponent,
  ],
  selector: 'expocab-footer',
  standalone: true,
  styleUrl: './footer.component.scss',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  readonly ROUTE_TOKENS = ROUTE_TOKENS;

  readonly activeProject$ = this.store.select(
    projectFeature.selectActiveProject,
  );

  readonly breadcrumbs$ = this.store
    .select(getRouterSelectors().selectUrl)
    .pipe(
      filter((url) => !!url),
      map((url) => this.urlParse(url)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

  readonly year = new Date().getFullYear();

  constructor(private store: Store) {}

  private urlParse(url: string): { [key: string]: unknown }[] {
    const breadcrumbs = [];

    if (url.includes(`/${this.ROUTE_TOKENS.FAQ}`)) {
      breadcrumbs.push({
        text: 'вопросы и ответы',
      });
    } else if (url.includes(`/${this.ROUTE_TOKENS.EXHIBITION.STAGES}`)) {
      breadcrumbs.push({
        text: 'этапы участия',
      });
    } else if (url.includes(`/${this.ROUTE_TOKENS.EXHIBITION.PREPARATION}`)) {
      breadcrumbs.push({
        text: 'подготовка к выставке',
      });
    } else if (url.includes(`/${this.ROUTE_TOKENS.EXHIBITION.DOCUMENTS}`)) {
      breadcrumbs.push({
        text: 'документы',
      });
    } else if (url.includes(`/${this.ROUTE_TOKENS.EXHIBITION.SETTINGS}/`)) {
      breadcrumbs.push({
        text: 'настройка участия',
      });
    } else if (url.includes(`/${this.ROUTE_TOKENS.PROFILE.PROFILE}`)) {
      breadcrumbs.push({
        text: 'мой профиль',
        url: this.ROUTE_TOKENS.PROFILE.PROFILE,
      });

      if (url.includes(`/${this.ROUTE_TOKENS.PROFILE.SETTINGS}`)) {
        breadcrumbs.push({
          text: 'настройки профиля',
        });
      } else if (url.includes(`/${this.ROUTE_TOKENS.PROFILE.NOTIFICATIONS}`)) {
        breadcrumbs.push({
          text: 'уведомления',
        });
      }
    }

    return breadcrumbs;
  }
}
