import { CdkMenuTrigger } from '@angular/cdk/menu';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { notificationFeature } from '@expocab/entities/notification';
import { projectFeature } from '@expocab/entities/project';
import { ROUTE_TOKENS } from '@expocab/shared/app-config';
import { SvgPathPipe } from '@expocab/shared/libs';
import { CircleButtonComponent } from '@expocab/ui/buttons/circle-button';
import { NotificationsMenuComponent } from '@expocab/widgets/notifications-menu';
import { UserMenuComponent } from '@expocab/widgets/user-menu';
import { getRouterSelectors } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { SvgIconComponent } from 'angular-svg-icon';
import { filter, map } from 'rxjs/operators';

import { LayoutGridComponent } from '../../grids';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    RouterLink,
    SvgPathPipe,
    CdkMenuTrigger,
    SvgIconComponent,
    CircleButtonComponent,
    LayoutGridComponent,
    UserMenuComponent,
    NotificationsMenuComponent,
  ],
  selector: 'expocab-header',
  standalone: true,
  styleUrl: './header.component.scss',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  readonly ROUTE_TOKENS = ROUTE_TOKENS;

  readonly activeProject$ = this.store.select(
    projectFeature.selectActiveProject,
  );

  readonly isArchive$ = this.store.select(getRouterSelectors().selectUrl).pipe(
    filter((url) => !!url),
    map((url) => url.includes(this.ROUTE_TOKENS.ARCHIVE.ARCHIVE)),
  );

  readonly isExhibitions$ = this.store
    .select(getRouterSelectors().selectUrl)
    .pipe(map((url) => url === `/${this.ROUTE_TOKENS.EXHIBITIONS}`));

  readonly newNotificationsCounter$ = this.store.select(
    notificationFeature.selectTotalNewNotifications,
  );

  readonly projects$ = this.store
    .select(projectFeature.selectProjectsWithContracts)
    .pipe(filter((projects) => !!projects.length));

  constructor(private store: Store) {}
}
