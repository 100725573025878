<div class="user-menu" cdkMenu>
  <div class="user-menu__wrapper">
    <div class="user-menu__header">
      <div class="user-menu__name expocab-text-big">
        {{ (user$ | async)?.username }}
      </div>
      <div class="user-menu__email expocab-text-base">
        {{ (user$ | async)?.email }}
      </div>
    </div>
    <div class="user-menu__body">
      <a
        class="user-menu__item expocab-text-base"
        cdkMenuItem
        [routerLink]="[
          ROUTE_TOKENS.PROFILE.PROFILE + '/' + ROUTE_TOKENS.PROFILE.SETTINGS
        ]"
        >Настройки профиля</a
      >
      <a
        class="user-menu__item expocab-text-base"
        cdkMenuItem
        [routerLink]="[
          ROUTE_TOKENS.PROFILE.PROFILE +
            '/' +
            ROUTE_TOKENS.PROFILE.NOTIFICATIONS
        ]"
        >Уведомления</a
      >
      <button
        class="user-menu__item expocab-text-base"
        cdkMenuItem
        (cdkMenuItemTriggered)="logout()"
      >
        <svg-icon [src]="'logout' | svgPath" />
        <span>Выйти из профиля</span>
      </button>
    </div>
  </div>
</div>
