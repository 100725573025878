import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { ButtonAction, ROUTE_TOKENS } from '@expocab/shared/app-config';
import { ButtonComponent } from '@expocab/ui/buttons/button';
import { Notification } from '@swagger/models';
import { NgxCutModule } from 'ngx-cut';

import { NotificationStatusComponent } from '../notification-status';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    DatePipe,
    NgxCutModule,
    NotificationStatusComponent,
    ButtonComponent,
  ],
  selector: 'expocab-notification',
  standalone: true,
  styleUrl: './notification.component.scss',
  templateUrl: './notification.component.html',
})
export class NotificationComponent {
  @Output() private closeMenuEvent = new EventEmitter<void>();
  @Output() private notificationHover = new EventEmitter<Notification['id']>();
  readonly ROUTE_TOKENS = ROUTE_TOKENS;
  readonly buttonAction = ButtonAction;
  @Input({ required: true }) notification!: Notification;

  closeMenu(): void {
    this.closeMenuEvent.emit();
  }

  notificationHoverEvent(id: Notification['id']): void {
    if (!this.notification.watched) this.notificationHover.emit(id);
  }
}
