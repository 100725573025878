<div class="toast toast_{{ toastType }}">
  <svg-icon [src]="toastType | svgPath" />
  <div class="toast__content">
    @if (title) {
      <p class="toast__title expocab-button-small">{{ title }}</p>
    }
    <p class="expocab-text-base">{{ message }}</p>
  </div>
  <svg-icon
    class="toast__close"
    [applyClass]="true"
    [src]="'close' | svgPath"
    (click)="remove()"
  />
</div>
