/* eslint-disable no-console */
import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import * as SentryBrowser from '@sentry/angular-ivy';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments';

SentryBrowser.init({
  dsn: 'https://a14eea3685b0102c23c3778e8a564e55@sentry.pir.ru/3',
  environment: environment.production ? 'production' : 'development',
  integrations: [
    SentryBrowser.browserTracingIntegration(),
    SentryBrowser.replayIntegration(),
  ],
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  tracePropagationTargets: ['localhost'],
  tracesSampleRate: 1.0,
});

if (environment.production) enableProdMode();

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
