import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LocalStorageService } from '@expocab/shared/api';
import { ROUTE_TOKENS, STORAGE_KEYS } from '@expocab/shared/app-config';

export const loginGuard: CanActivateFn = () => {
  const tokenKey = STORAGE_KEYS.AUTH_TOKEN;
  const localStorage = inject(LocalStorageService);
  const router = inject(Router);
  const authToken = localStorage.getItem(tokenKey);

  return !authToken ? true : router.navigate([`/${ROUTE_TOKENS.EXHIBITIONS}`]);
};
