import { CdkMenu, CdkMenuItem } from '@angular/cdk/menu';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  NotificationActions,
  notificationFeature,
} from '@expocab/entities/notification';
import { Store } from '@ngrx/store';
import { Notification } from '@swagger/models';
import { shareReplay } from 'rxjs/operators';

import { NotificationComponent } from '../notification';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, CdkMenu, CdkMenuItem, NotificationComponent],
  providers: [CdkMenu],
  selector: 'expocab-notifications-menu',
  standalone: true,
  styleUrl: './notifications-menu.component.scss',
  templateUrl: './notifications-menu.component.html',
})
export class NotificationsMenuComponent {
  readonly allNotifications$ = this.store.select(
    notificationFeature.selectNotifications,
  );

  readonly newNotificationsCounter$ = this.store
    .select(notificationFeature.selectTotalNewNotifications)
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  constructor(
    private store: Store,
    private cdkMenu: CdkMenu,
  ) {}

  closeMenu(): void {
    this.cdkMenu.menuStack.closeAll();
  }

  markAsWatched(id?: Notification['id']): void {
    const ids = id ? [id] : [];

    this.store.dispatch(
      NotificationActions.markNotificationsAsWatched({
        payload: { ids },
      }),
    );
  }
}
