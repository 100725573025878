import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { LocalStorageService } from '@expocab/shared/api';
import { STORAGE_KEYS } from '@expocab/shared/app-config';
import { Observable } from 'rxjs';

export function AuthInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const localStorage = inject(LocalStorageService);

  const authToken = localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN) ?? '';
  const authRequest = req.clone({
    setHeaders: {
      Authorization: `Token ${authToken}`,
      'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
  });

  return authToken ? next(authRequest) : next(req);
}
