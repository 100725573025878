<header class="header">
  <div class="container">
    <expocab-layout-grid
      showAsideRight
      [asideLeftPlace]="['center', 'start']"
      [asideRightPlace]="['center', 'end']"
    >
      <a aside-left [routerLink]="['/']">
        <svg-icon
          class="header__logo"
          [src]="'pirexpo' | svgPath"
          [applyClass]="true"
        />
      </a>
      <nav main class="header__nav">
        @for (project of projects$ | async; track project.id) {
          <a
            class="header__nav-item expocab-text-medium"
            queryParamsHandling="preserve"
            [class.header__nav-item_active]="
              project.id === (activeProject$ | async)?.id
            "
            [class.header__nav-item_notifications]="project.notification_count"
            [routerLink]="[ROUTE_TOKENS.EXHIBITIONS + '/' + project.id]"
          >
            <span class="header__nav-item-text">{{ project.name }}</span>
          </a>
        }
        <a
          class="header__nav-item expocab-text-medium"
          [class.header__nav-item_active]="isArchive$ | async"
          [routerLink]="[ROUTE_TOKENS.ARCHIVE.ARCHIVE]"
        >
          Архив
        </a>
      </nav>
      <div aside-right class="header__button-group">
        <expocab-circle-button
          class="header__button"
          size="big"
          type="white"
          icon="user"
          [cdkMenuTriggerFor]="userMenu"
        />
        @if ((isExhibitions$ | async) === false) {
          <expocab-circle-button
            class="header__button header__button_notifications"
            size="big"
            type="white"
            icon="bell"
            [class.header__button_notifications_active]="
              newNotificationsCounter$ | async
            "
            [cdkMenuTriggerFor]="notificationsMenu"
          />
        }
      </div>
    </expocab-layout-grid>
  </div>
</header>

<ng-template #userMenu>
  <expocab-user-menu />
</ng-template>

<ng-template #notificationsMenu>
  <expocab-notifications-menu />
</ng-template>
