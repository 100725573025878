import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  VERSION,
  isDevMode,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { EXPOCAB_CONFIG, ExpocabConfig } from '@expocab/shared/app-config';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet],
  selector: 'expocab-root',
  standalone: true,
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    @Inject(EXPOCAB_CONFIG) private readonly appConfig: ExpocabConfig,
  ) {
    if (isDevMode())
      // eslint-disable-next-line no-console
      console.log(
        `APP_DEV_MODE: ${!this.appConfig.production}, v.${VERSION.full}`,
      );
  }
}
