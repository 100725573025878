<div class="action-menu" cdkMenu>
  <div class="action-menu__wrapper">
    <button class="action-menu__item expocab-text-medium" cdkMenuItem>
      <svg-icon [src]="'info' | svgPath" />
      <span>Показать подсказки на странице</span>
    </button>
    <button class="action-menu__item expocab-text-medium" cdkMenuItem>
      <svg-icon [src]="'chat' | svgPath" />
      <span>Чат с поддержкой</span>
    </button>
    <a
      class="action-menu__item expocab-text-medium"
      cdkMenuItem
      [routerLink]="['/' + ROUTE_TOKENS.FAQ]"
    >
      <svg-icon [src]="'question' | svgPath" />
      <span>Вопросы и ответы</span>
    </a>
  </div>
</div>
