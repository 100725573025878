<footer class="footer expocab-button-big">
  <div class="container">
    <expocab-layout-grid
      [asideLeftPlace]="['center', 'start']"
      [asideRightPlace]="['center', 'end']"
    >
      <p aside-left class="footer__copyright">
        &copy; {{ year }} ООО &laquo;ПИР ЭКСПО&raquo;
      </p>

      <div main class="footer__breadcrumbs">
        <div
          class="footer__breadcrumb"
          [class.footer__breadcrumb_single]="!(breadcrumbs$ | async)?.length"
        >
          <a [routerLink]="['/']">Главная</a>
        </div>

        @if (activeProject$ | async; as activeProject) {
          <div class="footer__breadcrumb">
            <svg-icon
              class="footer__breadcrumb-icon"
              [src]="'arrow-right' | svgPath"
              [applyClass]="true"
            />
            <a [routerLink]="['/']">{{ activeProject?.name }}</a>
          </div>
        }

        @if (breadcrumbs$ | async; as breadcrumbs) {
          @for (breadcrumb of breadcrumbs; track breadcrumb) {
            <div class="footer__breadcrumb">
              <svg-icon
                class="footer__breadcrumb-icon"
                [src]="'arrow-right' | svgPath"
                [applyClass]="true"
              />
              <a [routerLink]="[breadcrumb['url']]">{{ breadcrumb['text'] }}</a>
            </div>
          }
        }
      </div>

      <!-- <a
        aside-right
        class="footer__faq"
        [routerLink]="['/' + ROUTE_TOKENS.FAQ]"
      >
        ВОПРОСЫ И ОТВЕТЫ
      </a> -->
    </expocab-layout-grid>
  </div>
</footer>
