import { UserActions } from '@expocab/entities/user';
import { ActionReducer, INIT, MetaReducer } from '@ngrx/store';

export function resetState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action != null && action.type === UserActions.userLogout.type) {
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer[] = [resetState];
