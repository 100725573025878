import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SvgPathPipe } from '@expocab/shared/libs';
import { SvgIconComponent } from 'angular-svg-icon';

type NotificationLevel = 'normal' | 'warning' | undefined;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SvgPathPipe, SvgIconComponent],
  selector: 'expocab-notification-status',
  standalone: true,
  styleUrl: './notification-status.component.scss',
  templateUrl: './notification-status.component.html',
})
export class NotificationStatusComponent {
  @Input({ required: true }) icon!: string;
  @Input({ required: true }) level!: NotificationLevel;
}
