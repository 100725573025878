import { CdkMenuTrigger } from '@angular/cdk/menu';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { NotificationActions } from '@expocab/entities/notification';
import { ProjectActions, projectFeature } from '@expocab/entities/project';
import { UserActions } from '@expocab/entities/user';
import { FooterComponent } from '@expocab/layout/footer';
import { HeaderComponent } from '@expocab/layout/header';
import { ScreenWidthDirective } from '@expocab/shared/api';
import { CircleButtonComponent } from '@expocab/ui/buttons/circle-button';
import { ActionMenuComponent } from '@expocab/widgets/action-menu';
import { Store } from '@ngrx/store';
import { Project } from '@swagger/models';
import { distinctUntilKeyChanged, filter } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    CdkMenuTrigger,
    ScreenWidthDirective,
    HeaderComponent,
    FooterComponent,
    ActionMenuComponent,
    CircleButtonComponent,
  ],
  selector: 'expocab-layout',
  standalone: true,
  styleUrl: './layout.component.scss',
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit {
  private readonly activeProject$ = this.store
    .select(projectFeature.selectActiveProject)
    .pipe(
      filter((activeProject): activeProject is Project => !!activeProject),
      distinctUntilKeyChanged('id'),
    );

  constructor(
    private store: Store,
    private destroyRef: DestroyRef,
  ) {}

  private loadNotifications(): void {
    this.activeProject$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((activeProject) =>
        this.store.dispatch(
          NotificationActions.loadNotifications({
            payload: { project: activeProject.id?.toString() },
          }),
        ),
      );
  }

  ngOnInit(): void {
    this.store.dispatch(UserActions.loadUser());
    this.store.dispatch(ProjectActions.loadProjects({}));
    this.loadNotifications();
  }
}
