import { CdkMenu, CdkMenuItem } from '@angular/cdk/menu';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ROUTE_TOKENS } from '@expocab/shared/app-config';
import { SvgPathPipe } from '@expocab/shared/libs';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CdkMenu, CdkMenuItem, RouterLink, SvgPathPipe, SvgIconComponent],
  selector: 'expocab-action-menu',
  standalone: true,
  styleUrl: './action-menu.component.scss',
  templateUrl: './action-menu.component.html',
})
export class ActionMenuComponent {
  readonly ROUTE_TOKENS = ROUTE_TOKENS;
}
