<div class="notifications-menu" cdkMenu>
  <div class="notifications-menu__wrapper">
    <div class="notifications-menu__header">
      <span class="expocab-text-medium"> Уведомления </span>
      <div
        class="notifications-menu__header-counter expocab-text-small"
        [class.notifications-menu__header-counter_active]="
          newNotificationsCounter$ | async
        "
      >
        <span>{{ newNotificationsCounter$ | async }}</span>
      </div>
    </div>
    <div class="notifications-menu__body">
      @for (notification of allNotifications$ | async; track notification.id) {
        <expocab-notification
          class="notifications-menu__notification"
          [notification]="notification"
          (notificationHover)="markAsWatched($event)"
          (closeMenuEvent)="closeMenu()"
        />
      } @empty {
        <div class="notifications-menu__empty expocab-text-base">
          У вас пока нет уведомлений
        </div>
      }
    </div>
    @if (newNotificationsCounter$ | async) {
      <div class="notifications-menu__footer">
        <button
          class="notifications-menu__button expocab-button-small"
          (click)="markAsWatched()"
        >
          Отметить все прочитанными
        </button>
      </div>
    }
  </div>
</div>
